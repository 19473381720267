<template>
  <div class="inner">
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="1"
        :pager-count="4"
        :page-sizes="pageSizes"
        :page-size="pagesize"
        :layout="layout"
        :total="totalData"
        :background="background"
        :small="small"
        :prev-text="prev_text"
        :next-text="next_text"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  name: 'pageination',
  data() {
    return {
      pageParams: {
        pageCurrent: 1,  // 当前页
        pageSize: this.pagesize    // 每页的条数
      },
    };
  },
  /*
  约定：
      totalData为Number类型且大于0；
      layout为String字符串，可选项：sizes, prev, pager, next, jumper, ->, total, slot
  * */
  props: {
    totalData: Number,
    layout: {
      type: String,
      default: 'prev,pager,next'
    },
    prev_text: {
      type: String,
      default: '◀'
    },
    next_text: {
      type: String,
      default: '▶'
    },
    small: {
      type: Boolean,
      default: false
    },
    background: {
      type: Boolean,
      default: false
    },
    pagesize: {
      type: Number,
      default: 2
    },
    pageSizes: {
      type: Array,
      default: function () {
        return [2, 4, 6, 8]
      }
    }
  },
  methods: {
    // 改变每页展示的数据大小
    handleSizeChange(val) {
      /**
       * 当触发事件改变了值 修改 pageParams 中的值 = val 然后触发绑在子组件上的父组件的事件
       *  将值传过去
       * 在父组件的事件里面 将得到的值修改到 pageParams 中 然后调用接口函数重新获取数据
       * pageBar 自定义的 方法
       */
      this.pageParams.pageSize = val;
      this.$emit("pageBar", this.pageParams);
    },
    // 改变当前页的页码
    handleCurrentChange(val) {
      this.pageParams.pageCurrent = val;
      this.$emit("pageBar", this.pageParams);
      this.toTop()
    }
  },
};
</script>
<style scoped>
.el-pagination button,
.el-pagination span:not([class*="suffix"]) {
  font-size: 12px;
}

.el-icon-refresh-right {
  float: right;
  margin-top: 4.2%;
  font-size: 23px;
  font-weight: lighter;
  margin-right: 1%;
  color: #606266;
  cursor: pointer;
}

/*这里是可以修改分页数字背景颜色的*/
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgb(63, 81, 181);
  color: #fff;
}
</style>
